import favouriteButton from 'javascripts/components/favouriteButton.js';
// // import favouriteButton from 'favouriteButton';
// import {favourite_item} from 'javascripts/mixins/favourite_item.js';

export default {
  name: 'organisationResult',
  components: {
      favouriteButton
  },
  props: {
      organisation: Object,
      favourites: {
          type: Array,
          default: () => []
      },
      favourites_enabled: {
          type: Boolean,
          default: false
      }
  },
  template:
    `
    <article class="organisation-result">
        <favourite-button
            v-if="favourites_enabled"
            :favourable_item="organisation"
            :favourites="favourites"
        ></favourite-button>

        <a v-if="typeof organisation.result_image !== 'undefined' && organisation.result_image.length" :href="organisation.path">
          <img class="result__image" :src="organisation.result_image" style="margin-bottom: 10px" :alt="organisation.name">
        </a>

        <header class="organisation-result__header">
            <div class="organisation-result__title-wrapper">
                <h1 class="organisation-result__title">
                    <a :href="organisation.path" class="organisation-result__title-link">
                        {{ organisation.name }}
                    </a>
                </h1>
            </div>
        </header>

        <div class="organisation-result__body">
            <p class="organisation-result__content" v-if="organisation.description">
                {{ organisation.description.substring(0,140) }}

                <span>{{organisation.description.length > 140 ? '...' : ''}}</span>
            </p>
            <p class="organisation-result__content" v-else-if="organisation.service_description">
                {{ organisation.service_description.substring(0,140) }}

                <span>{{organisation.service_description.length > 140 ? '...' : ''}}</span>
            </p>

            <div class="organisation-result__contact">
                <a :href="'mailto:' + organisation.email" v-if="organisation.email" class="organisation-result__contact-link">
                    <i class="fas fa-envelope mr-2"></i>
                    <span class="organisation-result__contact-text">{{ organisation.email }}</span>
                </a>

                <a :href="'tel:' + organisation.telephone.split(' ').join('')" v-if="organisation.telephone" class="organisation-result__contact-link">
                    <i class="fas fa-phone mr-2"></i>
                    <span class="organisation-result__contact-text">{{ organisation.telephone }}</span>
                </a>

                <div v-if="organisation.address_1">
                    <address class="post-item__address">
                        <i class="fas fa-map-marker-alt pr-2 post-item__icon"></i>
                        <span>
                            {{ organisation.address_1 }}
                            {{ organisation.address_2 }}
                            {{ organisation.town }}
                            {{ organisation.postcode }}
                        </span>
                    </address>

                    <div class="post-item__distance-miles" v-if="organisation.distance_in_miles >= 0">
                        About {{ organisation.distance_in_miles }} miles away
                    </div>
                </div>
                <div v-else-if="organisation.street_number">
                    <address class="post-item__address">
                        <i class="fas fa-map-marker-alt pr-2 post-item__icon"></i>
                        <span>
                            {{ organisation.street_number }}
                            {{ organisation.street }}
                            {{ organisation.town }}
                            {{ organisation.postcode }}
                        </span>
                    </address>

                    <div class="post-item__distance-miles" v-if="organisation.distance_in_miles >= 0">
                        About {{ organisation.distance_in_miles }} miles away
                    </div>
                </div>
            </div>
        </div>

        <a :href="organisation.path" class="organisation-result__cta">
            <span class="organisation-result__cta-text">Service Details</span>
        </a>
    </article>
  `,
}
