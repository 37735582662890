import "core-js/stable";
import "regenerator-runtime/runtime";

import 'stylesheets/walthamforest/application.scss'

import 'javascripts/cms_pages'
import 'javascripts/bookings'
import 'javascripts/next_events'
import 'javascripts/events'
import 'javascripts/organisations'
import 'javascripts/organisation_finder'
import 'javascripts/weather'
import 'javascripts/navbar'
import 'javascripts/blog_article_browser'
import 'javascripts/site_searches'
import 'javascripts/was_this_useful'

import ScrollPosStyler from 'scrollpos-styler';

import Glide, { Controls, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'

function ready(callback){
  // in case the document is already rendered
  if (document.readyState!='loading') callback();
  // modern browsers
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  // IE <= 8
  else document.attachEvent('onreadystatechange', function(){
      if (document.readyState=='complete') callback();
  });
}

ready(function(){

  // Print Page
  // var print = document.querySelector(".print-button");
  // print.addEventListener('click', function () {
  //   window.print()
  // });

  if (document.getElementsByClassName('glide').length) {
    setTimeout(function(){
      var glide = new Glide('.glide', {
        hoverpause: false,
        perView: 1,
        autoplay:8000,
      }).mount({ Controls, Autoplay })

      var play = document.querySelector("#js_glide_play");
      var pause = document.querySelector("#js_glide_pause");

      play.addEventListener('click', function () {
        glide.play();
      })

      pause.addEventListener('click', function () {
        glide.pause();
      })
    }, 5000);
  }
})
