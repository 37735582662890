import favouriteButton from 'javascripts/components/favouriteButton.js';

export default {
  name: 'eventResult',
  components: {
    favouriteButton
  },
  props: {
    event: Object,
    labels: {
        type: Object,
        default: () => {}
    },
    favourites: {
        type: Array,
        default: () => []
    },
    favourites_enabled: {
        type: Boolean,
        default: false
    },
    page: String,
  },
  template:
    `
    <article class="organisation-result post-item post-item--event">
        <favourite-button
            v-if="favourites_enabled"
            :favourable_item="event"
            :favourites="favourites"
        ></favourite-button>

        <a :href="event.path" class="post-item__link">
            <div class="post-item__heading-wrapper">
                <h4 class="post-item__heading">{{ event.name }}</h4>

                <div class="post-item__date">
                    <span class="post-item__date-item post-item__date-item--day">
                        {{ event.next_occuring_day }}
                    </span>
                    <span class="post-item__date-item post-item__date-item--month">
                        {{ event.next_occuring_month }}
                    </span>
                </div>
            </div>

            <div class="post-item__content-wrapper">
                <div class="post-item__image" v-if="typeof event.result_image !== 'undefined' && event.result_image.length">
                    <img class="result__image" :src="event.result_image" alt="">
                </div>

                <div class="post-item__body">
                    <div class="post-item__details">
                        <div class="post-item__detail">
                            <span v-if="labels.time" class="post-item__detail-label">{{ labels.time }}<span aria-hidden="true" class="post-item__detail-label--colon">:</span></span> <i v-else class="fas fa-clock pr-2 post-item__icon"></i><span class="sr-only">Time:</span> {{ event.time_for_event_result }}
                        </div>

                        <template v-if="event.additional_times_for_event_result">
                            <template v-for="(slot, index) in event.additional_times_for_event_result">
                                <div class="post-item__detail">
                                     <span v-if="labels.time" class="post-item__detail-label">{{ labels.time }}<span aria-hidden="true" class="post-item__detail-label--colon">:</span></span> <i v-else class="fas fa-clock pr-2 post-item__icon"></i><span class="sr-only">Additional Time {{ index }}:</span> {{ slot }}
                                </div>
                            </template>
                        </template>

                        <address class="post-item__address" v-if="event.address_1 || event.town || event.postcode">
                            <span v-if="labels.location" class="post-item__detail-label">{{ labels.location }}<span aria-hidden="true" class="post-item__detail-label--colon">:</span></span>
                            <i v-else class="fas fa-map-marker-alt pr-2 post-item__icon"></i>
                            <span>
                                {{ event.address_1 }}
                                {{ event.address_2 }}
                                {{ event.town }}
                                {{ event.postcode }}
                            </span>
                        </address>
                    </div>

                    <p v-if="page === 'home'" class="post-item__text" v-html="event.description_for_homepage"></p>
                    <p v-else class="post-item__text" v-html="event.description"></p>
                </div>
            </div>

            <div class="post-item__cta-wrapper">
                <div class="post-item__cta">
                  {{ labels.button }}
                </div>
            </div>
        </a>
    </article>
    `,
}
