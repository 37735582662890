import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import {focus_trap_mixin} from 'javascripts/mixins/focus_trap_mixin.js';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("bookings")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    Vue.component('booking_dates', {
      mixins: [focus_trap_mixin],
      props: {
      },
      data: function() {
        return {
          dates: [],
          slots_available: [],
          slot_date: null,
          category_id: null,
          available_slots: [],
          prev_month: null,
          next_month: null,
          future_slots_available: false,
          previous_slots_available: false,
          current_month: null,
          month_key: false,
          month_changed: false,
          chosen_date: "",
          chosen_slot_id: null,
          selected_month: null,
          show_spinner: false
        };
      },
      methods: {
        showSlots(id, date_string, slots) {
          this.available_slots = slots;
          this.chosen_date = date_string;
          this.chosen_slot_id = id;

          this.toggleOverlay();

          this.$nextTick(() => {
            if ( this.slotInputs != undefined && this.slotInputs != null && this.slotInputs.length ) {
              this.slotInputs[0].focus();
            }
          });
        },
        loadData(selected_month) {
          this.setCategory();
          let url = null;

          if (selected_month === undefined || selected_month === null) {
            url = '/bookings/slots.json?category=' + this.category_id
          } else {
            url = '/bookings/slots.json?category=' + this.category_id + "&from_month=" + selected_month
          }

          axios.get(url).then((response) => {
            this.dates = response.data.dates;
            this.slots_on_date = response.data.dates;
            this.prev_month = response.data.prev_month;
            this.next_month = response.data.next_month;
            this.future_slots_available = response.data.future_slots_available;
            this.previous_slots_available = response.data.previous_slots_available;
            this.current_month = response.data.current_month;
            this.show_spinner = false;

            if (this.month_changed) {
              this.month_key = !this.month_key;
            }
          });
        },
        changeMonth(date) {
          this.selected_month = date;
          this.month_changed = true;
          this.show_spinner = true;
          this.loadData(this.selected_month);
        },
        setCategory() {
          let urlParams = new URLSearchParams(window.location.search);
          this.category_id = urlParams.get('category');
        },
      },
      computed: {
        slotInputs() {
          return document.getElementsByClassName('js-slot-input');
        },
        dateItem() {
          document.getElementsByClassName('js-date-item');
        },
        focusableItems() {
          const CLASS_NAME = '.js-bookings-focusable';

          return document.querySelectorAll(`a[href]:not(${CLASS_NAME}), input:not(${CLASS_NAME}), button:not(${CLASS_NAME}), select:not(${CLASS_NAME}), textarea:not(${CLASS_NAME}), iframe, [tabindex], [contentEditable=true]`);
        }
      },
      mounted() {
        this.loadData(this.selected_month);

        setInterval(function () {
          this.loadData(this.selected_month);
        }.bind(this), 100000);
      }
    })

    Vue.component('booking_slots', {
      props: {
        show_overlay: Boolean,
        toggle_overlay_fn: Function,
        current_cat_id: String,
        available_slots: Array,
        chosen_date: String,
        chosen_slot_id: Number,
        load_data_fn: Function,
        selected_month: String
      },
      data: function() {
        return {
          slot_description: "",
          show_slot_description: false,
          chosen_slot_time: "",
          new_booking_contact_name: "",
          new_booking_contact_email: "",
          new_booking_contact_number: "",
          new_booking_comment: "",
          show_spinner: false,
          submission_attempted: false
        };
      },
      methods: {
        closeModal() {
          this.$emit('update:chosen_date', null);
          this.$emit('update:chosen_slot_id', null);
          this.$emit('update:available_slots', []);

          this.toggle_overlay_fn();
        },
        setSlotTime(slot_start, slot_id) {
          this.$emit('update:chosen_slot_id', slot_id);
          this.chosen_slot_time = slot_start;
        },
        getSlotData() {
          if (this.chosen_slot_id) {
            axios.get('/bookings/slots/' + this.chosen_slot_id + ".json").then((response) => {
              this.chosen_slot_time = response.data.slot.start_at;
              if (response.data.slot.description) {
                this.slot_description = response.data.slot.description;
                this.show_slot_description = true
              } else {
                this.show_slot_description = false
              }
            });
          }
        },
        book(e) {
          e.preventDefault();
          let formData = new FormData();


          if (this.chosen_slot_id) {
            formData.append('bookings_booking[contact_name]', this.new_booking_contact_name);
            formData.append('bookings_booking[contact_email]', this.new_booking_contact_email);
            formData.append('bookings_booking[contact_number]', this.new_booking_contact_number);
            formData.append('bookings_booking[comment]', this.new_booking_comment);
            this.show_spinner = true;
          }

          let url = "/bookings/slots/" + this.chosen_slot_id + "/bookings";
          axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
            this.submission_attempted = false;
            this.show_spinner = false;
            this.$parent.$emit('loadData');
            //window.location.href = "/bookings/slots/?category=" + this.current_cat_id;
            window.location.href = "/bookings/slots/complete"
          });
        }
      }
    })

    new Vue({
      el: "#bookings"
    })
  }
});
