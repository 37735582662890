export const google_places = {
    mounted() {
        const DEFAULT_BOUNDS = new google.maps.LatLngBounds(
            new google.maps.LatLng(54.445319, -1.594018),
            new google.maps.LatLng(54.862270, -1.090714)
        );

        const OPTIONS = {
            bounds: DEFAULT_BOUNDS,
            types: ['geocode'],
            componentRestrictions: {
                country: 'gb'
            }
        }
  
        this.autocomplete = new google.maps.places.Autocomplete(
            (this.$refs.autocomplete),
            OPTIONS
        );

        this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace();

            if (place.formatted_address) {
                this.search.location = place.formatted_address;
                this.search.latitude = place.geometry.location.lat().toFixed(5);
                this.search.longitude = place.geometry.location.lng().toFixed(5);
            }
        });
    }
  }
  