import Vue from 'vue/dist/vue.esm';
import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("organisation-finder")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    Vue.component('organisation-finder', {
      props: [
        'per_page'
      ],
      data: function () {
        return {
          pagination: {
            page: 1,
            total_count: 0,
          },
          organisations: [],
          search_term: ""
        };
      },
      template: `
        <div class="search-results-list">
          <input v-model="search_term" placeholder="Search" @keyup="loadData()">

          <p>Display organisations {{ ((pagination.page-1) * per_page)+1 }}-{{ pagination.page*per_page }} of {{ pagination.total_count }} records</p>

          <div class="result organisation" v-for="organisation in organisations">
          	<h5>
          		<a :href="organisation.link">{{ organisation.name }}</a>
          	</h5>

          	<div class="result-info">
          			<span class="email"><a href="mailto:hartmindfuture@yahoo.co.uk">{{ organisation.email }}</a></span>
          			<span class="telephone"><a href="tel:07896054361">{{ organisation.telephone }}</a></span>
                <span class="website"><a rel="external nofollow" target="_blank" :href="organisation.website">{{ organisation.website }}</a></span>
          	</div>
          </div>

          <button class="btn btn-small" @click="previousPage()">Previous</button>
          <button class="btn btn-small" @click="nextPage()">Next</button>
        </div>
      `,
      methods: {
        loadData() {
          axios.get('/organisations.json', { params: {
            "search_organisation[category_ids][]": "201",
            "search_organisation[term]": this.search_term,
            page: this.pagination.page,
            per_page: this.per_page
          }}).then((response) => {
            this.pagination = response.data.pagination;
            this.organisations = response.data.organisations;
          });
        },
        nextPage() {
          this.pagination.page = this.pagination.page + 1;
          this.loadData();
        },
        previousPage() {
          this.pagination.page = this.pagination.page - 1;
          this.loadData();
        }
      },
      mounted() {
        this.loadData()
      }
    })

    new Vue({
      el: "#organisation-finder"
    })
  }
});
