import Vue from 'vue/dist/vue.esm';
import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("weather")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    Vue.component('weather', {
      props: {
      },
      data: function() {
        return {
          temperature: null,
          summary: "",
          forecastio_icon: "",
          fontawesome_icon: "",
          location: "",
          time: "",
          fontawesome_map: {
            "clear-day": "sun",
            "clear-night": "moon",
            "rain": "cloud-rain",
            "snow": "snowflake",
            "sleet": "snowflake",
            "wind": "wind",
            "fog": "smog",
            "cloudy": "cloud",
            "partly-cloudy-day": "cloud-sun",
            "partly-cloudy-night": "cloud-moon",
          }
        };
      },
      methods: {
        loadData() {
          axios.get('/weather.json').then((response) => {
            this.temperature = Math.round(response.data.weather.temperature);
            this.summary = response.data.weather.summary;
            this.forecastio_icon = response.data.weather.icon;
            this.location = response.data.weather.location;

            this.fontawesome_icon = this.fontawesome_map[this.forecastio_icon];
          });
        },
        updateTime() {
          this.time = new Date().toTimeString().slice(0,5);
        }
      },
      mounted() {
        this.updateTime();
        this.loadData();

        setInterval(function () {
          this.updateTime();
        }.bind(this), 10000);
      }
    })

    new Vue({
      el: "#weather"
    })
  }
});
