import Vue from 'vue/dist/vue.esm';
import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("next-events")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    Vue.component('next-events', {
      props: {
        enable_pagination: Boolean,
        per_page: Number
      },
      data: function () {
        return {
          pagination: {
            page: 1,
            total_count: 0,
          },
          events: [],
          search_term: ""
        };
      },
      methods: {
        loadData() {
          axios.get('/events.json', { params: {
            page: this.pagination.page,
            per_page: this.per_page
          }}).then((response) => {
            this.pagination = response.data.pagination;
            this.events = response.data.events;
          });
        },
        nextPage() {
          this.pagination.page = parseInt(this.pagination.page) + 1;
          this.loadData();
        },
        previousPage() {
          this.pagination.page = parseInt(this.pagination.page) - 1;
          this.loadData();
        }
      },
      mounted() {
        this.loadData();
      }
    })

    new Vue({
      el: "#next-events"
    })
  }
});
